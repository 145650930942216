@use "../../config/styleVariable.scss" as global;
.customization-Box1 {
  width: 100%;
  border: 1px solid #80808047;
  margin: 20px 10px;
  padding: 10px;
  border-radius: 5px;
  max-height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  img {
    width: 100%;
    height: 400px;
  }
}
.customization-Box1 {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.customization-Box1::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.customization-Box1-Txt1 {
  font-size: 20px;
  font-family: global.$Bold;
}
.customization-Box1-Txt2 {
  font-size: 16px;
  font-family: global.$SemiBold;
  color: global.$grey2;
  padding: 10px;
}
.customization-Box1-Txt3 {
  font-size: 16px;
  font-family: global.$Medium;
  color: #000;

  img {
    width: 125px;
    height: 125px;
    object-fit: fill;
  }
}
.customization-Box1-Txt4 {
  font-size: 16px;
  font-family: global.$Medium;
  color: blue;
}
.Order-txt1 {
  font-size: 14px;
  font-family: global.$SemiBold;
  color: global.$grey2;
  margin-top: 10px;
}
.App-Designbutton {
  flex: 1;
  border: 1px solid rgb(245, 245, 245);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.069) 0px 4px 12px;
  height: 80%;
  margin-bottom: 20px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-StonesBox {
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}
