body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./assets/fonts/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Thin";
  src: url("./assets/fonts/DMSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "NanumMyeongjo-Regular";
  src: url("./assets/fonts/NanumMyeongjo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "ScheherazadeNew-SemiBold";
  src: url("./assets/fonts/ScheherazadeNew-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "ReemKufi-SemiBold";
  src: url("./assets/fonts/ReemKufi-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Beiruti-SemiBold";
  src: url("./assets/fonts/Beiruti-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Lemonada-SemiBold";
  src: url("./assets/fonts/Lemonada-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Marhey-SemiBold";
  src: url("./assets/fonts/Marhey-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Ruwudu-SemiBold";
  src: url("./assets/fonts/Ruwudu-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "ArefRuqaa-Bold";
  src: url("./assets/fonts/ArefRuqaa-Bold.ttf") format("truetype");
}
