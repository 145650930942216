@use "../../config/styleVariable.scss" as global;
.Header {
  height: 72px;
  margin-bottom: 45px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  @media screen and (max-width: 992px) {
    height: 70px;
    margin-bottom: 0px;
  }
}
.Header-margin {
  height: 114px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 45px;
  }
}
.Header-desktop {
  height: 100%;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.hideMob {
  @media only screen and (max-width: 480px) {
    display: none;
  }
}

.hidedesk {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.Header-mobile {
  height: 100%;
  width: 100%;
  @media screen and (min-width: 992px) {
    display: none;
  }
}
.Header-deskBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 0px 66px;
  box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.032);
}
.Header-mobBox {
  width: 100%;
  width: 100%;
}
.Header-deskLogo {
  height: 64px;
  object-fit: contain;
  cursor: pointer;
}
.Header-desk-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  padding: 9px 15px;
  font-family: global.$Bold;
  font-size: 15px;
  border-radius: 4px;
  text-transform: capitalize;
  white-space: nowrap;
  &:hover {
    color: global.$primary;
    cursor: pointer;
  }
}

.Header-desk-menu2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  padding: 4px 25px;
  font-family: global.$Bold;
  font-size: 15px;
  border-radius: 4px;
  text-transform: uppercase;
  &:hover {
    border-radius: 100px;
    color: global.$primary;
    cursor: pointer;
  }
}

.Header-searchBox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.Header-desk-Search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 0px;
  background-color: rgb(240, 240, 240);
  overflow: hidden;
  width: 100%;
  padding: 2px 10px;
  border-radius: 100px;
  color: #a0a0a0;
  input {
    background-color: transparent;
    border: 0px;
    width: 100%;
    padding: 7px 10px;
    font-size: 16px;
    font-family: global.$Medium;
    color: #000;
    &:focus {
      outline: none;
    }
  }
  &:hover {
    background-color: rgb(240, 240, 240);
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    display: none;
  }
}

.Header-mobile-Search {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.Header-deskCateogrey {
  background-color: #fff;
  height: 50px;
  width: 100%;
  padding: 0px 66px 6px;
  box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.032);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Header-deskCatItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: global.$Medium;
  margin-right: 50px;
  padding-top: 9px;
  font-size: 14px;
  border-bottom: 2px solid #ffffff00;
  line-height: 42px;
  text-transform: capitalize;
  white-space: nowrap;
  &:hover {
    color: global.$primary;
    cursor: pointer;
    border-bottom: 2px solid global.$primary;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  &.active {
    color: global.$primary;
    border-bottom: 2px solid global.$primary;
  }
}

.Header-deskCatItem2 {
  font-family: global.$Bold;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
}
.Header-mobkBox {
  width: 100%;
  box-shadow: 0 6px 4px 0px rgba(0, 0, 0, 0.058);
  height: 100%;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.083);
  }
}
.Header-mobkBox2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
}
.Header-mobLogo {
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}
.Header-deskCatItem3 {
  width: 100%;
  min-width: 50px;
  font-family: global.$Medium;
  font-size: 16px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 3px;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    color: global.$primary;
  }
}
.Header-deskactive {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 100px;
  color: global.$primary;
  cursor: pointer;
}

.searchPlaceInputHead-popover {
  background-color: #fff;
  width: 100%;
  margin-top: -48px;
  border-radius: 15px;
  min-height: 60px;
  box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.105);
  transition: 0.3s;
  padding: 10px;
  min-height: 50vh;
  overflow: scroll;
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 0px;
    height: 100vh;
  }
}

.SearchBar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 7px;
  font-family: global.$Medium;
  border-bottom: 1px solid rgb(242, 242, 242);
  &:hover {
    background-color: #ededed;
    border-radius: 6px;
    cursor: pointer;
  }
}

.SearchBar-input {
  background-color: rgb(241, 241, 241);
  width: 100%;
  padding: 15px 20px;
  font-size: 17px;
  padding: 10px;
  font-family: global.$Medium;
  &:focus {
    outline: none;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 20px;
  }
}
.SearchBar-txt1 {
  margin-left: 10px;
}

.SearchBar-mobileDiv {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  font-family: global.$Medium;
  font-size: 15px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.language-txt1 {
  padding: 3px 20px 0px 5px;
  margin-bottom: 5px;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    background-color: #ededed;
  }
}

.Sidebar-itemBox {
  display: flex;
  align-items: center;
  margin: 0px 0px 3px 0px;
  padding: 10px 7px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e9e9e9;
  }
}
.Sidebar-itemBox > span {
  padding-left: 15px;
  font: 500 16px global.$SemiBold;
}

.Sidebar-collapse {
  background-color: #fff;
  font-family: global.$Medium;
  font-size: 16px;
}
.Sidebar-txt1 {
  color: gray;
  font-family: global.$Medium;
  font-size: 16px;
  // margin-left: -10px;
  margin-left: 16px;
}
.Sidebar-txt2 {
  color: rgb(103, 103, 103);
  font-family: global.$Medium;
  font-size: 15px;
  margin-left: 10px;
}
.DrawerProfile-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.DrawerProfile-box > img {
  width: 65%;
}
.DesktopHeader-Icon {
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  font-family: global.$Medium;
  cursor: pointer;
}
.Header-ProfileImag {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  border: 1px solid grey;
  padding: 1px;
  margin-right: -10px;
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 39px;
    width: 39px;
    margin-right: 0px;
  }
}
// profilemenu----------------------------------/////////////

.profileMenu-Box1 {
  padding: 4px;
}
.profileMenu-Box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  padding-right: 50px;
  border-radius: 6px;
  margin-bottom: 2px;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
}
.profileMenu-Txt1 {
  font-family: global.$Medium;
  font-size: 14px;
}
.profileMenu-Img1 {
  margin-right: 15px;
  color: grey;
}
.LocationMobile-Txt1 {
  font-family: global.$SemiBold;
  font-size: 15px;
  display: flex;
  overflow: hidden;
}
.LocationMobile-Txt2 {
  flex: 1;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.LocationMobile-Box1 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-top: 1px solid rgb(228, 228, 228);
  height: 35px;
}
.Header-deskTxt2 {
  font-size: 12px;
  color: global.$primary;
}
.Header-location {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 8vw;
  color: #000;
  padding: 2px;
}
.Header-deskcat-2 {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.Header-deskCatItemtxt1 {
  font-family: global.$SemiBold;
  font-size: 25px;
  color: global.$primary;
}

.Header-deskCatItemline {
  margin: 10px;
  margin-left: 0px;
  height: 3px;
  background-color: rgb(231, 231, 231);
  width: 30%;
}

.Header-deskCatItemBox {
  border-left: 1px solid rgb(221, 221, 221);
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Header-deskCatItemImg {
  aspect-ratio: 1;
  object-fit: contain;
  background-color: #eeeeee;
}

.Header-deskCatItemtxt2 {
  font-family: global.$Medium;
  font-size: 14px;
  color: rgb(83, 83, 83);
}

.SearchBar-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.SearchBar-txt2 {
  font-family: global.$Medium;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
}
.SearchBar-txt3 {
  font-family: global.$Regular;
  color: gray;
  font-size: 14px;
}

.Header-txt2 {
  font-size: 10px;
  color: global.$primary;
  margin-bottom: -1px;
  margin-left: 1px;
}

.Sidebar-digitalGold {
  font-family: global.$Medium;
  font-size: 15px;
  border-radius: 4px;
  text-transform: capitalize;
  background-color: rgb(240, 240, 240);
  color: #b95c50;
  cursor: pointer;
}

.Header-deskTypeitem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Header-deskTypeimg {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  border-radius: 100px;
}
.SearchBar-list {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: grey lightgrey; 
}

.SearchBar-list::-webkit-scrollbar {
  width: 8px;
}

.SearchBar-list::-webkit-scrollbar-track {
  background: lightgrey; 
}

.SearchBar-list::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 4px;
}
