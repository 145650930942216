.category-table-card-image{
    height: 40px;
    width: 40px;
    object-fit: contain;
}

/* Hide the number input arrow */
.no-arrow-input::-webkit-outer-spin-button,
.no-arrow-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}