@use "../../../config/styleVariable.scss" as global;

.AddProducts-CoverImagePicker {
    border-radius: 6px;
    border: 2px dashed global.$grey5;
    background-color: global.$backround;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: global.$Medium;
    font-size: 13px;
    text-align: center;
    color: grey;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    &.img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }