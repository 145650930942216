@use "../../../config/styleVariable.scss" as global;
.ImagePicker-backgroud {
  border-radius: 6px;
  border: 2px dashed global.$grey5;
  background-color: global.$backround;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: global.$Medium;
  font-size: 13px;
  text-align: center;
  color: grey;
  margin-bottom: 20px;
  padding: 25px;
  position: relative;
  &.small {
    height: 170px;
    padding: 20px 10px;
  }
  &.large {
    height: 200px;
    width: 200px;
  }
  &.blogType {
    height: 300px;
  }
  &.categoryCards {
    height: 500px;
    width: 25vw;
  }
}
.imagePickerImg {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  object-fit: cover;
}
