.ContactUs_text1 {
  font-size: 18px;
  margin-left: 20px;
}
.ContactUs_text2 {
  font-size: 14px;
  margin-left: 20px;
}
.ContactUs_text3 {
  font-size: 14px;
}
.round-icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f9da7f;
}
.ContactUs_Box1 {
  margin: 10px 0px 20px;
  display: flex;
  padding: 10px 0px 10px;
}
.contactSubmit_Button {
  height: 50px;
  width: 100%;
  font-size: 15px;
}
.ContactUs_Box2 {
    margin: 10px 0px 20px;
    display: flex;
    align-items: center;
    padding: 10px 0px 10px;
}