.image-container {
  height: 60px;
  width: auto;
  overflow: hidden;
}

.icon-image {
  height: 50%;
  width: 50%;
  object-fit: contain;
}

.input-style {
  border: none;
  font-size: 18px;
  font-weight: 500;
}
.category-table-card-image{
  height: 30px;
}