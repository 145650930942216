@use "../../config/styleVariable.scss" as global;
.ShareScreen {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/shareScreenbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.ShareScreen-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0px 20px;
  justify-content: center;
  img {
    width: 200px;
  }
}


.ShareScreen-box {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  padding: 15px;
  background-image: linear-gradient(
    320deg,
    rgb(255, 255, 255) 0%,
    #fff 47%,
    #fffae4,
    #fff7d1
  );
  box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.032);
  @media screen and (min-width: 320px) and (max-width: 768px) {
    border: 0px;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.ShareScreen-box2 {
  display: flex;
  background-color: rgba(10, 111, 81, 0.9);
  border-radius: 15px;
  padding: 13px 15px;
  margin: 10px 0px;
}

.ShareScreen-box3 {
  background-color: rgb(255, 248, 233);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  border-radius: 100px;
  font-size: 23px;
  color: rgb(65, 55, 0);
}
.ShareScreen-titile {
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
}

.ShareScreen-logoBorder {
  background-color: #d0d0d0;
  width: 2px;
  margin-right: 20px;
}

.ShareScreen-txt2 {
  display: flex;
  font-family: global.$SemiBold;
  font-size: 14px;
  color: global.$grey6;
  align-items: center;
  @media screen and (max-width: 992px) {
    justify-content: center;
    text-align: center;
    padding-top: 10px;
  }
}

.ShareScreen-txt3 {
  color: #e4b50a;
  font-family: global.$SemiBold;
  font-size: 18px;
}

.ShareScreen-txt4 {
  font-family: global.$Regular;
  width: 90%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #e5e5e5;
}
