@use "../../config/styleVariable.scss" as global;

.homeScreen-loaderBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-left: -10px;
}

.HomeScreen-BannerBox {
  margin-top: -4px;
  width: 100%;
  overflow: hidden;
  overflow: hidden;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 0px;
  }
}

.HomeScreen-Banners {
  width: 100%;
  height: 420px;
  background-color: rgb(235, 235, 235);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    z-index: 1;
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 300px;
    background-size: cover;
    border-radius: 0px;

    &:before {
      width: 40%;
    }
  }
}

.HomeScreen-BannersBox2 {
  width: 50%;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
}

.HomeScreen-BannersBox {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 70px;
  z-index: -1;
  text-align: center;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 15px 15px;
    width: 100%;
  }
}

.Offers-box {
  padding: 0px !important;
  margin: 0px !important;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 12px !important;
  }
}

.Blogs-box {
  padding: 0px !important;
  margin: 0px !important;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 20px !important;
  }
}

.Blogs-row {
  width: 100%;
}
.category-row {
  width: 100%;
}

.Offers-img {
  width: 100%;
  height: 300px;
  width: 100%;
  object-fit: cover;
  background-color: rgb(235, 235, 235);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 250px;
  }
}

.Offers-txt1 {
  font-size: 24px;
  width: 60%;
  font-family: "NanumMyeongjo-Regular";
  color: #fff;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
}

.HomeScreen-Bannertxt2 {
  position: relative;
  font-family: "NanumMyeongjo-Regular";
  font-weight: 500;
  filter: invert(60%);
  mix-blend-mode: difference;
  z-index: 2;
  font-size: 38px;
  text-align: left;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 25px;
  }
}

.HomeScreen-Bannertxt3 {
  font-family: global.$Medium;
  font-size: 15px;
  margin-top: 10px;
  text-align: left;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
  }
}

.homescreen-category-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  text-align: center;
  font-family: global.$SemiBold;
  font-size: 15px;
  text-transform: capitalize;
  width: 120px;
  overflow: hidden;

  img {
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid rgb(255, 230, 201);
    padding: 3px;
    margin-bottom: 5px;
    background-color: rgb(255, 255, 254);
    &:hover {
      cursor: pointer;
      box-shadow: 0px 4px 6px 4px rgba(255, 155, 88, 0.1);
    }
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 80px;
    font-size: 14px;

    img {
      height: 80px;
      width: 80px;
    }
  }
}

.homescreen-category-arrows {
  margin-bottom: 10px;
  font-size: 35px;
  color: global.$grey7;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}

.HomeSCreen-space {
  padding-top: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 10px;
  }
}

.Homescreen-TopSellingStore {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin: 15px;
  }
}

.homescreen-txt1 {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px;
    display: none;
  }
}

.Homescreen-txt2 {
  padding-top: 30px;
  margin-bottom: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 0px;
    margin-left: 0px;
    font-size: 20px;
    border-bottom: 1px solid rgb(255, 230, 201);
  }
}

.Homescreen-txt3 {
  color: global.$primary;
}

.square {
  height: 5px;
  width: 5px;
  border-radius: 10px;
  background-color: rgb(209, 209, 209);

  &.active {
    background-color: global.$primary;
    width: 25px;
  }
}

.homescreen-parners {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  text-align: center;
  font-family: global.$SemiBold;
  font-size: 15px;
  text-transform: capitalize;
  width: 250px;
  overflow: hidden;

  img {
    height: 70px;
    width: 250px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid rgb(255, 230, 201);
    padding: 10px;
    margin-bottom: 5px;
    object-fit: contain;
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 200px;
    font-size: 14px;

    img {
      width: 200px;
    }
  }
}

.Horizontal-item2 {
  display: inline-block;
  vertical-align: middle;
  width: 280px;
  margin-right: 16px;
  height: 380px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 200px;
    height: 330px;
    margin-right: 5px;
  }
}

.HomeSCreen-InformationArea {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  overflow: hidden;
  width: 25vw;
  height: 500px;
  object-fit: cover;
  background-color: rgb(235, 235, 235);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 6px 4px rgba(255, 155, 88, 0.1);
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 60vw;
    height: 300px;
    margin-bottom: 20px;
  }
}

.HomeScreen-card-title {
  font-size: 26px;
  font-family: global.$Regular;
}

.HomeScreen-card-subHead {
  font-size: 14px;
  font-family: global.$Regular;
  font-weight: 200;
}

.HomeScreen-card-seeMore {
  font-size: 14px;
  font-family: global.$Regular;
  text-decoration: underline;
  color: cornflowerblue;
  cursor: pointer;
}

.HomeScreen_text1 {
  text-align: center;
  margin: 20px;
  font-size: 30px;
  font-family: "NanumMyeongjo-Regular";
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin: 10px;
    font-size: 25px;
  }
}

.HomeScreen_text2 {
  text-align: center;
  font-family: global.$SemiBold;
}
.category-box {
  padding: 0px !important;
  margin: 0px !important;
  @media screen and (max-width: 576px) {
    padding: 20px !important;
    height: 100%;
  }
}
.HomeScreen-GoldPrice {
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: -20px;
  }
}

.HomeScreen-GoldPriceBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid global.$primary;
  border-radius: 2px;
  padding: 10px;
  white-space: nowrap;
  height: 100%;
  font-size: 16px;
  color: #2a5e47;
  font-family: global.$SemiBold;
}
