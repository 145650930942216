@use "../../config/styleVariable.scss" as global;
.LiveRates-box {
    background-color: #fff;
  min-height: 100vh;
  // background-image: url("../../assets/images/waves.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.LiveRates-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.LiveRates-logo {
  width: 200px;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  img {
    width: 200px;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
   display: none;
  }
}

.LiveRates-box2 {
  padding: 20px 0px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px 0px;
   }
}


.LiveRates-txt1 {
  text-align: left;
  font-family: global.$SemiBold;
  font-size: 25px;
  color: #000;
  margin-top: 7px;
}

.LiveRates-txt2 {
  flex: 1;
  color: #000;
  font-family: global.$SemiBold;
  font-size: 22px;
  text-align: right;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    text-align: left;
    font-size: 14px;
  }
}

.LiveRates-txt3 {
  text-align: right;
  color: #000;
  margin-left: 10px;
  font-size: 30px;
  margin-bottom: 10px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
  font-size: 30px;
  }
}

.LiveRates-table {
  width: 100%;
  table-layout: fixed;
  thead {
    font-size: 24px;
    font-family: global.$Bold;
    tr {
      margin-bottom: 10px;
    }
    td {
      padding: 8px;
      text-align: center;
      color: #ffff;
      @media screen and (min-width: 320px) and (max-width: 768px) {
        font-size: 14px;
        padding: 5px;
      }
    }
  }
  tbody {
    td {
      font-size: 18px;
      font-family: global.$Medium;
      padding: 5px;
      text-align: center;
      color:black;
      @media screen and (min-width: 320px) and (max-width: 768px) {
        font-size: 14px;
        padding: 5px;
      }
    }
  }
}


.LiveRates-card1{
  overflow: hidden;
  border-radius: 6px;
  background-color: global.$primary;
  margin-bottom: 4px;
  box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.032);
}
// #778899
.LiveRates-card2{
  overflow: hidden;
  border-radius: 6px;
  background-color:    #f1ebdb; 
  backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
  margin-bottom: 2px;
}

.LiveRates-label{
  @media screen and (min-width: 320px) and (max-width: 768px) {
   width: 120px;
  }
}

.LiveRates-txt4{
  background-image: linear-gradient(red, rgb(255, 49, 49));
  padding: 5px 15px;
  min-width: 150px;
  color: #fff;
  border-radius: 4px;
}

