@use "../../config/styleVariable.scss" as global;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
.product-landing {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px 10px 0px 10px;
  }
  margin: 15px 0px;
}
.product-details {
  margin-bottom: 8px;
  font-size: 17px;
}

.productDetails-image1 {
  // max-height: 480px;
  img {
    object-fit: contain;
    width: 100%;
    overflow: hidden;
    max-height: 480px;
  }
}

.productDetails-image2 {
  object-fit: contain;

  // border: 1px solid rgb(225, 225, 225);
  padding: 2px;
}

.productDetails-image-row {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productDetails-scrollable-parent {
  overflow-x: hidden;
  float: left;
}
.productDetails-scrollable {
  align-items: center;
  justify-content: center;
  width: 1000px;
  float: left;
  font-size: 15px;
  font-family: arial;
  cursor: pointer;
}

.productDetails-image-scroll-content {
  display: inline-block;
}

.productDetails-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.productDetails-text1 {
  font-size: 32px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
    margin: 20px 0px;
  }
}

.productDetails-text2 {
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}
.productDetails-text3 {
  font-size: 16px;
}
.productDetails-text4 {
  font-size: 14px;
}
.productDetails-txt-bold {
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}
.productDetails-secondary-text {
  color: rgba(51, 50, 50, 0.626);
}
.productDetails-zero-margin-h {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.productDetails-cart-btn {
  width: 100%;
}

.productDetails-counter-btn {
  background-color: #f1f2f68d;
  color: #000;
}

.productDetails-counter-button {
  display: flex;
  align-items: center;
  // border: 1px solid rgba(188, 187, 187, 0.353);
  width: min-content;
  padding: 6px 12px;
  border-radius: 8px;
}

.productDetails-text-btn1 {
  display: flex;
  align-items: center;
  color: global.$primary;
  font-weight: bold;
}
.text-color-primaryy {
  color: global.$primary;
}
.productDetails-text-btn1:hover {
  background-color: transparent !important;
}

.productDetails-counter-control {
  box-shadow: none !important;
  color: #000;
  border: none;
  background-color: #f4f4f4;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productDetails-counter-control:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.productDetails-count {
  font-size: 1.7rem;
  font-weight: 600;
  margin: 0 16px;
}

//common
.productDetails-flex {
  display: flex;
  flex-direction: row;
  gap: 0px;
  @media (max-width: 762px) {
    flex-direction: column;
    gap: 10px;
  }
}
.productDetails-flex-row {
  flex-direction: row;
}
.productDetails-v-center {
  align-items: center;
}
.productDetails-h-center {
  justify-content: center;
}
.productDetails-space-between {
  justify-content: space-between;
}
.productDetails-align-end {
  align-self: flex-end;
}
.productDetails-flex-end {
  align-items: center;
  justify-content: flex-end;
}

.productDetails-p-6-12 {
  padding: 6px 12px;
}
.productDetails-margin-right-2 {
  margin-right: 2px;
}
.productDetails-margin-h-6 {
  font-size: 16px;
  margin: 0px 0px 0px 0px;
  @media (max-width: 762px) {
    margin: 0px 0px 0px 0px;
  }
  &.price {
    font-size: 24px;
    @media (max-width: 762px) {
      font-size: 17px;
    }
  }
}
.productDetails-margin-v-8 {
  margin: 8px 0px 8px 0px;
}
.productDetails-margin-b-32 {
  margin-bottom: 20px;
}
.productDetails-margin-b-16 {
  margin-bottom: 10px;
}

.ProductDetailScreen-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ProductDetailScreen-btn {
  width: 100%;
  // font-family: global.$SemiBold;
  border-radius: 10px;
  padding: 12px;
  border: 0px;
  color: #fff;
  &.primary {
    background-color: global.$primary;
  }
  &.grey {
    // background-color: global.$grey1;
  }
}
.ProductDetailScreen-reviewButton {
  background-color: transparent;
  border: 1px solid global.$grey5;
  font-family: global.$SemiBold;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  color: #000;
}
.ProductDetailScreen-txt4 {
  font-family: global.$SemiBold;
  color: global.$grey1;
  font-size: 16px;
  margin-bottom: 10px;
}
.review-modal-text {
  font-size: 14px;
  color: global.$grey4;
}
.review-item-card + .review-item-card {
  margin-top: 20px;
}
.product-details-brand-name {
  color: rgb(109, 109, 109);
  font-size: 17px;
}

.productDetails-txt1 {
  font-size: 18px;
  color: green;
  font-weight: bold;
}

.productDetails-txt2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.productDetails-txt3 {
  color: rgb(10, 48, 175);
  font-size: 18px;
  margin-top: 10px;
}
.cstmslider {
  & .slick-track {
    margin-left: 0;
  }
}
.product_screen_slider_image {
  cursor: pointer;
  object-fit: contain;
  position: relative;
  &.active {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 0px 6px 0px, #ff006a 0px 0px 6px 0px;
  }
}
/////
.productDetails-box {
  background-color: #d1d1d1;
  padding: 40px 0px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px 0 !important;
}
.productDetailsInfo-titil {
  text-align: center;
  font-family: global.$Bold;
  font-size: 28px;
  color: #2c3e50;
  padding-bottom: 30px;
  letter-spacing: 0.5px;
}
.productInfo-titil {
  text-align: center;
  color: #2a5e47;
  font-family: global.$Bold;
  font-size: 20px;
  padding-bottom: 20px;
  letter-spacing: 0.3px;
}
.productInfo-div1 {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.productInfo-text {
  color: #333;
  font-family: global.$Regular;
  font-size: 15px;
  letter-spacing: 0.2px;
}
@media screen and (max-width: 768px) {
  .zoom {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .box-mobile {
    display: none;
  }
}
.shipping_information{
  font-size: 15px;
  font-weight: 800;
}
.information_text{
  font-size: 17px;
}