@use "../../config/styleVariable.scss" as global;
.Blogs-landing {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px 10px 0px 10px;
  }
  margin: 30px 0px;
}

.Blog-image1 {
  margin-top: 35px;
  max-height: 400px;
  background-color: #f4f3f3;
  // background-color: red;
  border-radius: 12px;
  img {
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
    overflow: hidden;
    max-height: 400px;
  }
}
.Blog-details {
  padding-top: 10px;
margin-top: 18px;
}
.BlogDetails-content {
  padding-top: 15px;
  font-size: 17px;
  font-family: global.$Regular;
  min-height: 200px;
  // background-color: red;
  // @media screen and (min-width: 320px) and (max-width: 768px) {
  //   font-size: 13px;

  // }
}
.BlogDetails-txt-bold {
  font-weight: bold;
}
.Blog-details-txt1 {
  margin-top: 25px;
  font-size: 32px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
    margin: 20px 0px;
  }
}
.BlogDetails-desc {
  font-size: 14px;
  font-weight: 500;
  color: rgb(109, 109, 109);
}
