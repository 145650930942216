@use "../../config/styleVariable.scss" as global;
.ProductItem {
  background-color: #3d3838;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 124px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
  background-color: rgb(253, 253, 253);
  border: 1px solid rgb(255, 242, 226);
}
.ProductItem-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  transition: scale 100ms;
  background-color: rgb(253, 253, 253);
  &:hover{
    scale: 1.05;
  }
}
.ProductItem-Box1 {
  flex: 1;
  overflow: hidden;
  padding: 10px;
}
.ProductItem-Box2 {
  padding: 15px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 10px;
  }
}
.ProductItem-txt1 {
  font-family: global.$Medium;
  text-transform: capitalize;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
  }
}
.ProductItem-txt2 {
  font-family: global.$Regular;
  font-size: 13px;
  color: rgb(92, 92, 92);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 12px;
  }
}
.ProductItem-txt3 {
  font-family: global.$Bold;
  font-size: 18px;
  font-weight: 600;
  @media (max-width:762px) {
    font-size: 16px;
  }
}
.ProductItem-txt5{
  font-size: 12px;
}
